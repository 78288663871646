<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :hasChanges="hasChanges"
    :customClass="'create-quotation create-form'"
    v-if="
      getPermission('quotation:create') || getPermission('quotation:update')
    "
  >
    <template v-slot:header-title>
      <div class="d-flex justify-space-between">
        <h1 class="form-title text-truncate d-flex align-baseline">
          Quotation for
          <div
            v-if="vCustomerId"
            class="ml-3 form-title-link cursor-pointer text-h5"
            v-on:click="openDialog('customer')"
          >
            {{ vCustomer.display_name }}
          </div>
          <template v-else>
            <div
              class="ml-3 form-title-link cursor-pointer text-h5"
              v-on:click="openDialog('customer')"
            >
              Select a Company
              <v-icon large color="cyan">mdi-account-check-outline</v-icon>
            </div>
            <div class="mx-3">OR</div>
            <div
              class="form-title-link cursor-pointer cyan--text text--darken-4 text-h5"
              v-on:click="createCustomerDialog()"
            >
              Create a new Company
              <v-icon large color="cyan darken-4"
                >mdi-plus-circle-outline</v-icon
              >
            </div>
          </template>
        </h1>
        <v-chip
          label
          color="chip-custom-blue"
          outlined
          class="text-white p-3 mr-4"
          style="font-size: 21px !important; font-weight: 700"
        >
          <template>
            {{ dbQuotation.barcode }}
          </template>
        </v-chip>
      </div>
    </template>
    <template v-slot:header-action>
      <div>
        <v-btn
          :disabled="formLoading"
          v-on:click="goBack()"
          class="custom-bold-button custom-grey-border"
          depressed
          >Cancel</v-btn
        >
        <v-btn
          class="custom-bold-button ml-4"
          depressed
          :disabled="
            !formValid ||
            formLoading ||
            ((customError || customErrorOcc) &&
              quoteInvoiceParameters.invoice_type == 'recurring')
              ? true
              : false
          "
          :loading="formLoading"
          v-on:click="validateJob()"
          color="cyan white--text"
          >Save</v-btn
        >
      </div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <div class="bg-white px-2">
          <v-form
            ref="createQuotationForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="validateJob()"
          >
            <v-row>
              <!--  <v-col md="12">
          <div class="form-action">
            <div class="form-title">
              Quotation for {{ reCustomerId }}
              <span
                class="form-title-link cursor-pointer"
                v-on:click="openDialog('customer')"
              >
                <template v-if="reCustomerId && reCustomerId > 0">{{
                  vCustomer.display_name
                }}</template>
                <template v-else>
                  Select a Customer
                  <v-icon large color="cyan">mdi-plus-circle-outline</v-icon>
                </template>
              </span>
            </div>
            <div>
              <v-chip
                label
                color="chip-custom-blue"
                outlined
                class="text-white mr-2"
                style="font-size: 21px !important; font-weight: 700"
                >{{ dbQuotation.barcode }}</v-chip
              >
              <v-btn
                :disabled="formLoading"
                v-on:click="goBack()"
                class="custom-bold-button custom-grey-border"
                depressed
                >Cancel</v-btn
              >
              <v-btn
                class="custom-bold-button ml-4"
                depressed
                :disabled="!formValid || formLoading"
                :loading="formLoading"
                v-on:click="validateJob()"
                color="cyan white--text"
                >Save</v-btn
              >
            </div>
          </div>
        </v-col> -->
              <v-col md="7">
                <label for="quotation-title" class="">Opportunity</label>
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="select-Opportunity"
                  :items="opportunityList"
                  :disabled="formLoading"
                  :loading="formLoading"
                  v-model.trim="dbQuotation.opportunity"
                  placeholder="Select Opportunity"
                  solo
                  flat
                  item-color="cyan"
                  item-text="opportunity_name"
                  item-value="id"
                  hide-details
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Opportunity Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <label for="quotation-title" class="mt-2">Date</label>
                <DatePicker solo :default-date=" dbQuotation.date "
                v-model="dbQuotation.date" mandatory: true />
                <label for="quotation-title" class="required mt-2"
                  >Quotation Title</label
                >
                <TextField
                  id="quotation-title"
                  dense
                  filled
                  placeholder="Title"
                  solo
                  flat
                  counter="50"
                  v-model="dbQuotation.title"
                  color="cyan"
                  :maxlength="250"
                  :rules="[validateRules.required(dbQuotation.title, 'Title')]"
                />
                <div class="mt-2">
                  <TextAreaField
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    v-model="dbQuotation.description"
                    placeholder="Description"
                    solo
                    flat
                    row-height="25"
                    counter="250"
                  />
                </div>
                <v-row>
                  <v-col md="6">
                    <v-layout class="align-center">
                      <v-flex md3
                        ><label class="my-0">Billing Location</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          v-on:click="openDialog('billing')"
                          small
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vBilling?.id">
                      <span v-if="vBilling && vBilling.property_address">
                        <!--  <v-icon small>mdi-map-marker</v-icon> --></span
                      >
                      <template>
                        <div
                          class="custom-flex-d"
                          v-if="vBilling.property_name"
                        >
                          <b>
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              color="#000"
                              class="cmt-2"
                            >
                              mdi-home-map-marker
                            </v-icon>
                          </b>
                          <span>{{ vBilling.property_name }}<br /></span>
                        </div>
                        <div v-else>
                          <em class="text-muted">
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              color="#000"
                              class="cmt-2"
                            >
                              mdi-home-map-marker
                            </v-icon>
                            no Site Name</em
                          >
                        </div>
                      </template>

                      <template>
                        <div class="custom-flex-d">
                          <b>
                            <v-icon
                              style="
                                font-size: 18px;
                                margin-top: -3px !important;
                              "
                              color="#000"
                              class="cmt-2"
                            >
                              mdi-map-marker
                            </v-icon>
                          </b>
                          <span>
                            <template v-if="vBilling.unit_no"
                              >{{ vBilling.unit_no }},</template
                            >
                            <template v-if="vBilling.street_1"
                              >{{ vBilling.street_1 }},</template
                            >
                            {{ vBilling.street_2 }}
                            <br />
                            {{ vBilling.country }}
                            <template
                              v-if="
                                vBilling.zip_code &&
                                vBilling.zip_code != '000000'
                              "
                            >
                              {{ vBilling.zip_code }}
                            </template>
                          </span>
                        </div>
                      </template>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">
                        Please select billing location
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>
                    <v-layout class="mt-3 align-center">
                      <v-flex md3
                        ><label class="my-0">Contact Details</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openDialog('billing-contact')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="vBillingContact?.id">
                      <p class="mb-0">{{ vBillingContact.display_name }}</p>
                      <p class="mb-0">{{ vBillingContact.primary_phone }}</p>
                      <p class="mb-0">{{ vBillingContact.primary_email }}</p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select contact person</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>
                    <v-layout v-if="false" class="mt-3">
                      <v-flex md4
                        ><label class="my-0">Email Notification </label></v-flex
                      >
                      <v-flex md8>
                        <v-switch
                          v-model="dbQuotation.notify_billing_contact_person"
                          class="m-0 p-0"
                          color="cyan"
                          dense
                          inset
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col md="6">
                    <v-layout class="align-center">
                      <v-flex md3
                        ><label class="my-0">Site Location</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openDialog('property')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>

                    <template v-if="vProperty?.id">
                      <span v-if="vProperty && vProperty.property_address">
                        <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                      >
                      <p
                        class="mr-2"
                        v-if="vProperty && vProperty.property_address"
                      >
                        <template>
                          <div
                            class="custom-flex-d"
                            v-if="vProperty?.property_name"
                          >
                            <b>
                              <v-icon
                                style="
                                  font-size: 18px;
                                  margin-top: -3px !important;
                                "
                                color="#000"
                                class="cmt-2"
                              >
                                mdi-home-map-marker
                              </v-icon>
                            </b>
                            <span>{{ vProperty?.property_name }}<br /></span>
                          </div>
                          <div v-else>
                            <em class="text-muted">
                              <v-icon
                                style="
                                  font-size: 18px;
                                  margin-top: -3px !important;
                                "
                                color="#000"
                                class="cmt-2"
                              >
                                mdi-home-map-marker
                              </v-icon>
                              no Site Name</em
                            >
                          </div>
                        </template>

                        <template>
                          <div class="custom-flex-d">
                            <b>
                              <v-icon
                                style="font-size: 18px; margin-top: -3px"
                                color="#000"
                                class="cmt-2"
                              >
                                mdi-map-marker
                              </v-icon>
                            </b>
                            <span>
                              <template v-if="vProperty?.unit_no"
                                >{{ vProperty?.unit_no }},</template
                              >
                              <template v-if="vProperty?.street_1"
                                >{{ vProperty?.street_1 }},</template
                              >
                              {{ vProperty?.street_2 }}
                              <br />
                              {{ vProperty?.country }}
                              <template
                                v-if="
                                  vProperty?.zip_code &&
                                  vProperty?.zip_code != '000000'
                                "
                              >
                                {{ vProperty?.zip_code }}
                              </template>
                            </span>
                          </div>
                        </template>
                      </p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">
                        Please select service location
                      </p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>

                    <v-layout class="mt-3 align-center">
                      <v-flex md3
                        ><label class="my-0">Contact Details</label></v-flex
                      >
                      <v-flex md9 class="position-relative">
                        <v-icon
                          small
                          v-on:click="openDialog('property-contact')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>

                    <template v-if="vPropertyContact?.id">
                      <p class="mb-0">{{ vPropertyContact.display_name }}</p>
                      <p class="mb-0">{{ vPropertyContact.primary_phone }}</p>
                      <p class="mb-0">{{ vPropertyContact.primary_email }}</p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select contact person</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select customer</p>
                    </template>

                    <v-layout v-if="false" class="mt-3">
                      <v-flex md4
                        ><label class="my-0">Email Notification </label></v-flex
                      >
                      <v-flex md8>
                        <v-switch
                          v-model="dbQuotation.notify_property_contact_person"
                          class="m-0 p-0"
                          color="cyan"
                          dense
                          inset
                        />
                      </v-flex>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="5">
                <table width="100%" style="table-layout: fixed">
                  <!-- <tr>
                    <td width="150" class="font-weight-600">
                      <label>Rate opportunity</label>
                    </td>
                    <td class="font-weight-700">
                      <v-rating
                        :readonly="formLoading"
                        v-model.trim="dbQuotation.rating"
                        background-color="orange lighten-3"
                        color="orange"
                      ></v-rating>
                    </td>
                  </tr> -->
                  <!-- <tr>
                    <td width="150" class="font-weight-600">
                      <label for="reference-no"
                        Customer Reference # (For PDF as Your Ref.)</label
                      >
                    </td>
                    <td>
                      <v-text-field
                        id="reference-no"
                        v-model.trim="dbQuotation.reference"
                        dense
                        filled
                        :disabled="formLoading"
                        :loading="formLoading"
                        color="cyan"
                        placeholder="Customer Reference #"
                        :rules="[
                          validateRules.minLength(
                            dbQuotation.reference,
                            'Customer Reference',
                            1
                          ),
                          validateRules.maxLength(
                            dbQuotation.reference,
                            'Customer Reference',
                            100
                          ),
                        ]"
                        solo
                        flat
                      ></v-text-field>
                    </td>
                  </tr> -->
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="your-reference-no"
                        >Internal Reference #</label
                      >
                    </td>
                    <td>
                      <v-text-field
                        id="your-reference-no"
                        v-model.trim="dbQuotation.your_reference"
                        dense
                        filled
                        :disabled="formLoading"
                        :loading="formLoading"
                        color="cyan"
                        placeholder="Internal Reference #"
                        :rules="[
                          validateRules.minLength(
                            dbQuotation.your_reference,
                            'Internal Reference',
                            1
                          ),
                          validateRules.maxLength(
                            dbQuotation.your_reference,
                            'Internal Reference',
                            100
                          ),
                        ]"
                        solo
                        flat
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="sales-representatives"
                        >Sales representatives</label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="sales-representatives"
                        :items="userList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbQuotation.sales_executive"
                        placeholder="Sales representatives"
                        solo
                        flat
                        item-color="cyan"
                        item-text="display_name"
                        item-value="id"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Sales Representative Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="prepared-by" class="required"
                        >Prepared by</label
                      >
                    </td>
                    <td>
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="prepared-by"
                        :items="userList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        :rules="[
                          validateRules.required(
                            dbQuotation.prepared_by_id,
                            'Prepared by'
                          ),
                        ]"
                        v-model.trim="dbQuotation.prepared_by_id"
                        placeholder="Prepared by"
                        solo
                        flat
                        item-color="cyan"
                        item-text="display_name"
                        item-value="id"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No User Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="prepared-by" class="required"
                        >Validity
                      </label>
                    </td>
                    <td>
                      <!--  <pre>{{ validPeriod }}</pre> -->
                      <v-autocomplete
                        dense
                        color="cyan"
                        filled
                        id="prepared-by"
                        :items="validPeriod"
                        :rules="[
                          validateRules.required(
                            dbQuotation.validity_till,
                            'Validity'
                          ),
                        ]"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbQuotation.validity_till"
                        placeholder="validity"
                        solo
                        flat
                        item-color="cyan"
                        item-text="text"
                        item-value="value"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No User Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label for="tags">Tags </label>
                    </td>
                    <td>
                      <tag-auto-complete
                        v-if="related_type"
                        :type="tag_type"
                        v-model="dbQuotation.tags"
                        placeholder="tags"
                      />
                    </td>
                  </tr>
                  <tr v-if="false">
                    <td>
                      <label for="type">Type</label>
                    </td>
                    <td>
                      <div class="d-flex ml-1">
                        <v-btn
                          depressed
                          tile
                          :outlined="!(dbQuotation.type == 1)"
                          v-on:click="
                            dbQuotation.type = 1 /*  changeContractStatus() */
                          "
                          value="1"
                          color="light-green darken-3 white--text"
                          >New</v-btn
                        >
                        <v-btn
                          class="mx-2"
                          depressed
                          tile
                          :outlined="!(dbQuotation.type == 2)"
                          v-on:click="
                            dbQuotation.type = 2 /*  changeContractStatus() */
                          "
                          value="2"
                          color="orange darken-4 white--text"
                          >Maintenance</v-btn
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="150" class="font-weight-600">
                      <label>Is Currency ?</label>
                    </td>
                    <td valign="middle">
                      <v-autocomplete
                        hide-details
                        v-model="dbQuotation.is_currency"
                        :items="currencyType"
                        dense
                        flat
                        filled
                        item-color="cyan"
                        color="cyan"
                        solo
                        item-value="value"
                        item-text="text"
                      >
                      </v-autocomplete>
                    </td>
                  </tr>
                  <template v-if="!$route?.query?.edit && false">
                    <tr>
                      <td width="150" class="font-weight-600">
                        <label>Is Contract ?</label>
                      </td>
                      <td valign="middle">
                        <v-autocomplete
                          hide-details
                          v-on:change="enableContract($event)"
                          v-model="dbQuotation.is_contract"
                          :items="contractType"
                          dense
                          flat
                          filled
                          item-color="cyan"
                          color="cyan"
                          solo
                          item-value="value"
                          item-text="text"
                        >
                        </v-autocomplete>
                        <!--  <v-tooltip
                    top
                    content-class="custom-top-tooltip"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-switch
                        class="m-0 my-2 p-0"
                        color="cyan"
                        dense
                        v-on:change="enableContract($event)"
                        inset
                        hide-details
                      ></v-switch>
                    </template>
      <span>Contract ?</span>
      </v-tooltip> -->
                      </td>
                    </tr>
                  </template>
                </table>
              </v-col>
              <v-col
                cols="12"
                v-if="dbQuotation.type == 2 && !$route?.query?.edit && false"
                ref="qtContract"
              >
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue d-flex align-center"
                    >
                      Contract
                      <v-checkbox
                        v-if="false"
                        color="cyan"
                        hide-details
                        class="ml-5 py-0 my-0"
                        :value="contract_status"
                        v-on:change="changeContractStatus()"
                      />
                    </h3>
                  </v-card-title>
                  <v-card-text v-if="contract_status" class="p-6 font-size-16">
                    <div>
                      <recurring-schedule
                        :key="`contract-recurring-schedule-${schedule_key}`"
                      />
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" ref="qtLineItem">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title
                      class="headline grey lighten-4"
                      @click="show_line_items = !show_line_items"
                    >
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        <v-icon>{{
                          !show_line_items
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                        Line Items
                      </h3>
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text
                        v-show="show_line_items"
                        ref="lineItem"
                        class="font-size-16 py-0"
                      >
                        <line-item
                          v-if="related_type > 0"
                          :related-type="related_type"
                          is-job
                          :related-detail="updatedQuotationData"
                          :db-line-items="dbLineItems"
                          :db-equipments="dbEquipments"
                          is-quotation
                        />
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-container>
              </v-col>
              <v-col
                cols="12"
                ref="invoicing"
                v-if="is_quotation_invoice_allow && false"
              >
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title
                      class="headline grey lighten-4"
                      @click="show_invoicing = !show_invoicing"
                    >
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        <v-icon>{{
                          !show_invoicing
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                        INVOICING
                      </h3>
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text
                        v-show="show_invoicing"
                        ref="invoicing"
                        class="font-size-16 py-0"
                      >
                        <v-row>
                          <v-col md="6">
                            <v-row>
                              <v-col md="6">
                                <v-row>
                                  <v-col md="5" class="my-auto py-0">
                                    <label
                                      class="mb-0"
                                      for="visit-one-off-start-date"
                                      >Is Invoice Needed</label
                                    >
                                  </v-col>
                                  <v-col md="7" class="py-0">
                                    <v-select
                                      id="invoice-needed"
                                      dense
                                      v-model="
                                        quoteInvoiceParameters.invoice_needed
                                      "
                                      :items="neededInvoiceList"
                                      item-color="cyan"
                                      item-text="text"
                                      item-value="value"
                                      class="pl-0"
                                      filled
                                      hide-details
                                      placeholder="Is Invoice Needed"
                                      solo
                                      flat
                                      color="cyan"
                                      @change="
                                        updateRecurringInvoiveSchedule(
                                          'quoteInvoiceParameters.invoice_needed',
                                          $event
                                        )
                                      "
                                    />
                                  </v-col>
                                </v-row>
                              </v-col>
                              <template
                                v-if="
                                  quoteInvoiceParameters?.invoice_needed == 1
                                "
                              >
                                <v-col md="6">
                                  <v-row>
                                    <v-col md="5" class="my-auto py-0">
                                      <label
                                        class="mb-0"
                                        for="visit-one-off-start-date"
                                        >Invoice Type</label
                                      >
                                    </v-col>
                                    <v-col md="7" class="py-0">
                                      <v-select
                                        :readonly="
                                          quotation_recurring_invoice
                                            ? false
                                            : true
                                        "
                                        :disabled="
                                          quotation_Id == 0 ? false : true
                                        "
                                        id="invoice-type"
                                        dense
                                        v-model="
                                          quoteInvoiceParameters.invoice_type
                                        "
                                        :items="invoiceTypes"
                                        item-color="cyan"
                                        item-text="text"
                                        item-value="value"
                                        class="pl-0 mb-1"
                                        filled
                                        hide-details
                                        placeholder="Invoice Type"
                                        solo
                                        flat
                                        color="cyan"
                                        @change="
                                          updateRecurringInvoiveSchedule(
                                            'quoteInvoiceParameters.invoice_type',
                                            $event
                                          )
                                        "
                                      />
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col md="6">
                                  <v-row>
                                    <v-col md="5" class="my-auto py-0">
                                      <label
                                        class="mb-0"
                                        for="invoice-one-off-start-date"
                                        >Invoice Creation
                                      </label>
                                    </v-col>
                                    <v-col md="7" class="py-0">
                                      <v-select
                                        id="invoice-type"
                                        dense
                                        v-model="
                                          quoteInvoiceParameters.invoice_generate_type
                                        "
                                        :items="generateTypeList"
                                        item-color="cyan"
                                        item-text="text"
                                        item-value="value"
                                        class="pl-0 mb-1"
                                        filled
                                        hide-details
                                        placeholder="Invoice Creation"
                                        solo
                                        flat
                                        color="cyan"
                                        @change="
                                          updateRecurringInvoiveSchedule(
                                            'quoteInvoiceParameters.invoice_generate_type',
                                            $event
                                          )
                                        "
                                      />
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col
                                  md="6"
                                  v-if="
                                    quoteInvoiceParameters?.invoice_type !=
                                    'recurring'
                                  "
                                >
                                  <v-row>
                                    <v-col md="5" class="my-auto py-0">
                                      <label
                                        class="mb-0"
                                        for="visit-one-off-start-date"
                                        >Invoice Date</label
                                      >
                                    </v-col>
                                    <v-col md="7" class="py-0">
                                      <date-picker
                                        key="invoice-date"
                                        id="invoice-date"
                                        :min-date="todayDate"
                                        placeholder="Invoice Date"
                                        v-model="
                                          quoteInvoiceParameters.invoice_date
                                        "
                                        @change="
                                          updateRecurringInvoiveSchedule(
                                            'quoteInvoiceParameters.invoice_date',
                                            $event
                                          )
                                        "
                                      />
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col md="6">
                                  <v-row>
                                    <v-col md="5" class="my-auto py-0">
                                      <label
                                        class="mb-0"
                                        for="visit-one-off-start-date"
                                        >Payment Due</label
                                      >
                                    </v-col>
                                    <v-col md="7" class="py-0">
                                      <v-select
                                        id="invoice-type"
                                        dense
                                        v-model="
                                          quoteInvoiceParameters.payment_due
                                        "
                                        :items="paymentDueList"
                                        item-color="cyan"
                                        item-text="text"
                                        item-value="value"
                                        class="pl-0 mb-1"
                                        filled
                                        hide-details
                                        placeholder="Invoice Type"
                                        solo
                                        flat
                                        color="cyan"
                                        @change="
                                          updateRecurringInvoiveSchedule(
                                            'quoteInvoiceParameters.payment_due',
                                            $event
                                          )
                                        "
                                      />
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col
                                  md="6"
                                  v-if="
                                    quoteInvoiceParameters?.invoice_type ==
                                    'recurring'
                                  "
                                >
                                  <v-row>
                                    <v-col md="5" class="py-0 my-auto">
                                      <label
                                        class="mb-0"
                                        for="invoice-one-off-start-date"
                                        >Invoice criteria
                                      </label>
                                    </v-col>
                                    <v-col md="5" class="py-0">
                                      <v-select
                                        id="invoice-type"
                                        dense
                                        v-model="
                                          quoteInvoiceParameters.invoice_amount_type
                                        "
                                        :items="amountTypeList"
                                        item-color="cyan"
                                        item-text="text"
                                        item-value="value"
                                        class="pl-0 mb-1"
                                        filled
                                        hide-details
                                        placeholder="Invoice Type"
                                        solo
                                        flat
                                        color="cyan"
                                        @change="
                                          updateRecurringInvoiveSchedule(
                                            'quoteInvoiceParameters.invoice_amount_type',
                                            $event
                                          )
                                        "
                                      />
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col
                                  md="6"
                                  v-if="
                                    quoteInvoiceParameters?.invoice_type ==
                                    'recurring'
                                  "
                                >
                                  <table width="100%">
                                    <tr>
                                      <td
                                        class="m-0 px-4 font-size-16 font-weight-500 custom-grey-border"
                                        style="color: black"
                                      >
                                        <template
                                          v-if="
                                            quoteInvoiceParameters?.invoice_amount_type ==
                                            'total_amount'
                                          "
                                        >
                                          Generate
                                          {{ invoiceScheduleOutput?.length }}
                                          Invoice for the quote amount $
                                          {{
                                            vCalculations?.total_non_text_amount ??
                                            0.0
                                          }}.
                                        </template>
                                        <template v-else>
                                          Generate
                                          {{ invoiceScheduleOutput?.length }}
                                          Invoice for (1/{{
                                            invoiceScheduleOutput?.length
                                          }}) the quote amount $
                                          {{
                                            vCalculations?.total_non_text_amount ??
                                            0.0
                                          }}.
                                        </template>
                                      </td>
                                    </tr>
                                  </table>
                                </v-col>
                                <v-col
                                  md="12"
                                  v-if="
                                    quoteInvoiceParameters?.invoice_type ==
                                    'recurring'
                                  "
                                >
                                  <table width="100%">
                                    <tr>
                                      <td
                                        class="m-0 px-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
                                        style="color: red"
                                      >
                                        Please note that all invoice parameters
                                        will be refreshed when line items or
                                        frequency parameters are changed or
                                        edited in the quote during creation or
                                        revision of quote.
                                      </td>
                                    </tr>
                                  </table>
                                </v-col>
                              </template>
                            </v-row>
                          </v-col>
                          <v-col
                            md="6"
                            class="my-auto"
                            v-if="
                              quoteInvoiceParameters?.invoice_type ==
                                'recurring' &&
                              quoteInvoiceParameters?.invoice_needed == 1
                            "
                          >
                            <div>
                              <RecurringSchedule
                                :key="`job-recurring-schedule-${schedule_key}`"
                                isQuotation
                              />
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-container>
              </v-col>
              <v-col cols="12" ref="qtNote">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title
                      class="headline grey lighten-4"
                      @click="show_notes_attachment = !show_notes_attachment"
                    >
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        <v-icon>{{
                          !show_notes_attachment
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                        INTERNAL NOTES
                      </h3>
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text
                        class="p-6 font-size-16"
                        v-show="show_notes_attachment"
                      >
                        <v-layout>
                          <v-flex class="mr-4" md6>
                            <label class="font-size-16 font-weight-600"
                              >Internal Note</label
                            >
                            <!--  <v-autocomplete
                              dense
                              color="cyan"
                              filled
                              label="Internal Note"
                              solo
                              flat
                              class="my-2"
                              item-color="cyan"
                              item-text="label"
                              item-value="id"
                              hide-details
                              :disabled="formLoading"
                              :loading="formLoading"
                              :items="adminNoteList"
                              v-model="dbQuotation.admin_note_id"
                              v-on:change="update_admin_note()"
                            >
                            </v-autocomplete> -->
                            <tiny-mce
                              :disabled="formLoading"
                              v-model="dbQuotation.admin_note"
                            />
                          </v-flex>
                          <v-flex md6>
                            <label class="font-size-16 font-weight-600"
                              >Client Note
                              <!-- <span class="text--secondary font-weight-500"
                                  >(visible on PDF)</span
                                > --></label
                            >
                            <!--  <v-autocomplete
                                dense
                                color="cyan"
                                filled
                                label="Client Note"
                                solo
                                flat
                                class="my-2"
                                item-color="cyan"
                                item-text="label"
                                item-value="id"
                                hide-details
                                :disabled="formLoading"
                                :loading="formLoading"
                                :items="clientNoteList"
                                v-model="dbQuotation.client_note_id"
                                v-on:change="update_client_note()"
                              >
                              </v-autocomplete> -->
                            <tiny-mce
                              :disabled="formLoading"
                              v-model="dbQuotation.client_note"
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout class="mt-4">
                          <v-flex md12 class="mr-4" v-if="false">
                            <label class="font-size-16 font-weight-600"
                              >Attachments</label
                            >
                            <file-upload
                              v-model="dbQuotation.attachments"
                              :maxLimit="5"
                            >
                            </file-upload>
                          </v-flex>
                          <v-flex md12>
                            <DocumnetList
                              v-model="dbQuotation.attachments"
                              :document-type="2"
                            >
                            </DocumnetList>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-container>
              </v-col>
              <v-col cols="12" class="pb-15" ref="qtTermsConditions">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title
                      class="headline grey lighten-4"
                      @click="show_terms_condition = !show_terms_condition"
                    >
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        <v-icon>{{
                          !show_terms_condition
                            ? "mdi-chevron-down"
                            : "mdi-chevron-up"
                        }}</v-icon>
                        TERMS &amp; CONDITIONS
                      </h3>
                    </v-card-title>
                    <v-expand-transition>
                      <v-card-text
                        class="p-6 font-size-16"
                        v-show="show_terms_condition"
                      >
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          label="Terms &amp; Conditions"
                          solo
                          flat
                          class="mb-2"
                          item-color="cyan"
                          item-text="label"
                          item-value="id"
                          hide-details
                          :disabled="formLoading"
                          :loading="formLoading"
                          :items="termsConditionList"
                          v-model="dbQuotation.term_condition_id"
                          v-on:change="update_term_condition()"
                        />
                        <tiny-mce
                          :disabled="formLoading"
                          v-model="dbQuotation.term_conditions"
                        />
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-container>
              </v-col>
            </v-row>
          </v-form>

          <customer-select
            :dialog="reCustomerDialog"
            @close="closeCustomerDialog"
          />
          <customer-create :key="`quotation-customer-create-${dialog_key}`" />
          <person-select
            :dialog="contactDialog"
            v-on:close="contactDialog = false"
            :type="contactType"
          />
          <address-select
            :dialog="propertyDialog"
            v-on:close="propertyDialog = false"
            :type="propertyType"
            :label="propertyLabel"
          />
          <Dialog :common-dialog="contractDialog" :dialog-width="680">
            <template v-slot:title> Contract Detail </template>
            <template v-slot:body>
              <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
                <v-container fluid>
                  <v-row>
                    <v-col md="6">
                      <label for="duration-value" class="font-weight-600"
                        >Duration</label
                      >
                      <v-text-field
                        dense
                        filled
                        id="duration-value"
                        color="cyan"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-mask="'##'"
                        v-model.trim="dbQuotation.duration_value"
                        label="Duration"
                        solo
                        flat
                        hide-details
                        v-on:keyup="updateDuration()"
                      ></v-text-field>
                    </v-col>
                    <v-col md="6">
                      <label for="duration-type" class="font-weight-600"
                        >Duration Type</label
                      >
                      <v-select
                        dense
                        color="cyan"
                        filled
                        id="duration-type"
                        :items="durationTypeList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbQuotation.duration_type"
                        v-on:change="updateDuration()"
                        label="Duration"
                        solo
                        flat
                        item-color="cyan"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Duration Type Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col md="6">
                      <label for="duration-value" class="font-weight-600"
                        >Contract Start Date</label
                      >
                      <date-picker
                        solo
                        flat
                        :default-date="dbQuotation.contract_start"
                        v-model="dbQuotation.contract_start"
                      ></date-picker>
                    </v-col>
                    <v-col md="6">
                      <label for="duration-value" class="font-weight-600"
                        >Contract End Date</label
                      >
                      <date-picker
                        solo
                        flat
                        :default-date="dbQuotation.contract_end"
                        v-model="dbQuotation.contract_end"
                      ></date-picker>
                    </v-col>
                    <v-col md="6">
                      <label for="duration-value" class="font-weight-600"
                        >Base</label
                      >
                      <v-select
                        dense
                        color="cyan"
                        filled
                        id="duration-type"
                        :items="baseList"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-model.trim="dbQuotation.base_type"
                        label="Base"
                        solo
                        flat
                        item-color="cyan"
                        hide-details
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Duration Type Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col md="6">
                      <label for="duration-value" class="font-weight-600"
                        >Base No.</label
                      >
                      <v-text-field
                        dense
                        filled
                        id="no-of-services"
                        color="cyan"
                        :disabled="formLoading"
                        :loading="formLoading"
                        v-mask="'####'"
                        v-model.trim="dbQuotation.total_service"
                        label="Base No."
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </template>
            <template v-slot:action>
              <v-btn
                v-on:click="contractDialog = false"
                class="mx-2 custom-grey-border custom-bold-button"
              >
                No! Close
              </v-btn>
              <v-btn
                v-on:click="saveData()"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
              >
                Yes! Continue
              </v-btn>
            </template>
          </Dialog>
        </div>
      </perfect-scrollbar>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import Dialog from "@/view/pages/partials/Dialog";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import LineItemV2 from "@/view/pages/partials/Line-Item-V2.vue";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import moment from "moment-timezone";
import ObjectPath from "object-path";
import {
  SET_DIALOG_STATUS,
  SET_CREATE_DIALOG_STATUS,
  CLEAR_CUSTOMER,
} from "@/core/services/store/customer.module";
import {
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
  RESET_CREATE_STATE,
} from "@/core/services/store/visit.module";
import {
  UPLOAD,
  QUERY,
  POST,
  PATCH,
} from "@/core/services/store/request.module";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import CustomerCreate from "@/view/components/CustomerCreateDialog.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import KTCookie from "@/assets/js/components/cookie";
import DocumnetList from "@/view/pages/DocumnetList";
import TagAutoComplete from "@/view/components/TagAutoCompleteInput.vue";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import RecurringSchedule from "@/view/pages/invoice/RecurringInvoiceSchedule.vue";
import {
  CLEAR_INVOICE,
  UPDATE_INVOICE_SCHEDULE_RECURRING_QUOTE,
  UPDATE_SCHEDULE_LIST_QUOTE,
  UPDATE_SCHEDULE_STATE,
} from "@/core/services/store/invoice.module";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "create-quotation",
  mixins: [ValidationMixin, CommonMixin],
  data() {
    return {
      schedule_key: Number(new Date()),
      dialog_key: Number(new Date()),
      formLoading: false,
      formValid: true,
      quotationCreated: false,
      show_line_items: false,
      show_notes_attachment: false,
      show_terms_condition: false,
      related_type: 0,
      tag_type: null,
      contractDialog: false,
      quotation_Id: 0,
      quotation_recurring_invoice: false,
      is_quotation_invoice_allow: false,
      durationTypeList: [
        { text: "Year", value: "year" },
        { text: "Month", value: "month" },
      ],
      baseList: [
        { text: "No. of Services", value: "service_no" },
        { text: "Hours", value: "hours" },
        { text: "None", value: "none" },
      ],
      paymentDueList: [
        { value: 2, text: "Net 15" },
        { value: 3, text: "Net 30" },
        { value: 4, text: "Net 45" },
      ],
      neededInvoiceList: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],
      invoiceTypes: [
        { value: "one-off", text: "One Off" },
        { value: "recurring", text: "Recurring" },
      ],
      contractType: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      currencyType: [
        {
          text: "SGD",
          value: 1,
        },
      ],
      validPeriod: [
        { value: 15, text: "15 Days" },
        { value: 30, text: "30 Days" },
        { value: 60, text: "60 Days" },
        { value: 90, text: "90 Days" },
      ],
      show_invoicing: false,
      dbQuotation: {
        barcode: null,
        title: null,
        description: null,
        billing_contact_person: false,
        property_contact_person: false,
        rating: 1,
        type: 1,
        tags: [],
        date: null,
        prepared_by_id: null,
        sales_executive: null,
        opportunity: null,
        reference: null,
        open_till: null,
        validity_till: null,
        /*   sale_by: null, */
        /*     customer_ref: null, */
        your_reference: null,
        /*   quote_by: null, */
        term_condition_id: 0,
        term_conditions: null,
        /*   admin_note_id: 0, */
        admin_note: null,
        /*  client_note_id: 0, */
        client_note: null,
        attachments: [
          {
            file: null,
            name: null,
            remark: null,
            suffix: null,
            tags: null,
            search: null,
            start_date: null,
            end_date: null,
            reminder_date: null,
          },
        ],
        is_contract: 0,
        is_currency: "SGD",
        duration_value: 1,
        duration_type: "year",
        contract_start: moment().format("YYYY-MM-DD"),
        contract_end: moment().add(1, "year").format("YYYY-MM-DD"),
        duration: null,
        total_service: null,
        base_type: "service_no",
      },
      generateTypeList: [
        {
          value: "auto_create_on_acceptance",
          text: "Auto create on quote acceptance",
        },
        {
          value: "auto_create_on_send_acceptance",
          text: "Auto create and send on quote acceptance",
        },
      ],
      amountTypeList: [
        {
          value: "total_amount",
          text: "Generate all invoices for the quote amount.",
        },
        {
          value: "each_amount",
          text: "Generate all invoices for (1/No. of Schedule) the quote amount",
        },
      ],
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      adminNoteList: [],
      clientNoteList: [],
      termsConditionList: [],
      userList: [],
      opportunityList: [],
      dbLineItems: [],
      dbEquipments: [],
      todayDate: null,
      updatedQuotationData: {},
      propertyType: null,
      propertyLabel: null,
      propertyDialog: false,
      contactType: null,
      contactDialog: false,
      contract_status: false,
    };
  },
  components: {
    "customer-select": CustomerSelect,
    "customer-create": CustomerCreate,
    "person-select": PersonSelect,
    "address-select": AddressSelect,
    /*  "recurring-schedule": RecurringSchedule, */
    "line-item": LineItemV2,
    DatePicker,
    "tiny-mce": TinyMCE,
    // "file-upload": FileUpload,
    "tag-auto-complete": TagAutoComplete,
    TextAreaField,
    Dialog,
    CreateUpdateTemplate,
    DocumnetList,
    RecurringSchedule,
  },
  methods: {
    updateRecurringInvoiveSchedule(key, value) {
      this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING_QUOTE, {
        key: key,
        value: value,
      });
      this.$store.commit(UPDATE_SCHEDULE_STATE, {
        key: "isEdit",
        value: false,
      });
      this.generateInvoiceScheduleQuote();
    },
    generateInvoiceScheduleQuote() {
      /*  let invoiceArray = []; */
      let invoiceArrayTotelAmt = [];
      let total = 0;
      if (this.quoteInvoiceParameters?.invoice_amount_type == "total_amount") {
        total = this.vCalculations?.total_non_text_amount;
      } else {
        total =
          this.vCalculations?.total_non_text_amount /
          Number(this.invoiceRecurringSchedule?.occurrence);
      }
      invoiceArrayTotelAmt = this.invoiceScheduleOutput?.map((item) => ({
        daysOfWeek: item?.daysOfWeek,
        startDate: item?.startDate,
        dueDate: this.dueDate(item?.startDate),
        billing_amount: total,
        status: 1,
        isEdited: 0,
        isDisabled: 0,
        isPrimary: 0,
        otherItem: item?.otherItem ?? [],
        toatalAmt: item?.toatalAmt ?? 0,
      }));
      this.$store.commit(UPDATE_SCHEDULE_LIST_QUOTE, {
        key: "dbScheduleOutput",
        value: invoiceArrayTotelAmt,
      });
    },
    dueDate(date) {
      let day = 15;
      if (this.quoteInvoiceParameters?.payment_due == 2) {
        day = 15;
      } else if (this.quoteInvoiceParameters?.payment_due == 3) {
        day = 30;
      } else if (this.quoteInvoiceParameters?.payment_due == 4) {
        day = 45;
      }
      let dueData = moment(date).add(day, "days").format("YYYY-MM-DD");
      return dueData;
    },
    saveData() {
      this.contractDialog = false;
    },
    enableContract(param) {
      if (param) {
        this.contractDialog = true;
        /*    this.dbQuotation.is_contract = true;
        this.dbQuotation.duration_value = 1;
        this.dbQuotation.duration_type = "year";
        this.dbQuotation.contract_start = moment().format("YYYY-MM-DD");
        this.dbQuotation.contract_end = moment()
          .add(1, "year")
          .format("YYYY-MM-DD");
        this.dbQuotation.total_service = null; */
      } else {
        this.contractDialog = false;
        /*  this.dbQuotation.is_contract = false;
        this.dbQuotation.duration_value = 0;
        this.dbQuotation.duration_type = null;
        this.dbQuotation.contract_start = null;
        this.dbQuotation.contract_end = null;
        this.dbQuotation.total_service = null; */
      }
    },
    updateDuration() {
      const { contract_start, duration_value, duration_type } =
        this.dbQuotation;
      this.dbQuotation.contract_end = moment(contract_start)
        .add(duration_value, duration_type)
        .format("YYYY-MM-DD");
    },
    changeContractStatus() {
      this.schedule_key = Number(new Date());
      this.$nextTick(() => {
        this.contract_status = this.dbQuotation.type == 2;
      });
    },
    createCustomerDialog() {
      this.dialog_key = Number(new Date());
      this.$nextTick(() => {
        this.$store.commit(SET_CREATE_DIALOG_STATUS, true);
      });
    },
    remove_row(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.add_attachment_row();
      }
    },
    update_term_condition() {
      let output = this.lodash.find(this.termsConditionList, {
        id: this.dbQuotation.term_condition_id,
      });
      if (output) {
        this.dbQuotation.term_conditions = output.description;
      }
    },
    update_admin_note() {
      let output = this.lodash.find(this.adminNoteList, {
        id: this.dbQuotation.admin_note_id,
      });
      if (output) {
        this.dbQuotation.admin_note = output.description;
      }
    },
    update_client_note() {
      let output = this.lodash.find(this.clientNoteList, {
        id: this.dbQuotation.client_note_id,
      });
      if (output) {
        this.dbQuotation.client_note = output.description;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    closeCustomerDialog() {
      this.$store.commit(SET_DIALOG_STATUS, false);
    },
    openDialog(type) {
      this.$store.commit(SET_DIALOG_STATUS, false);
      this.propertyType = null;
      this.propertyLabel = null;
      this.propertyDialog = false;
      this.contactType = null;
      this.contactDialog = false;

      if (type == "customer") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "billing") {
          this.propertyType = "billing";
          this.propertyLabel = "Billing Location";
          this.propertyDialog = true;
        }
        if (type == "billing-contact") {
          this.contactType = "billing";
          this.contactDialog = true;
        }
        if (type == "property") {
          this.propertyType = "property";
          this.propertyLabel = "Service Location";
          this.propertyDialog = true;
        }
        if (type == "property-contact") {
          this.contactType = "property";
          this.contactDialog = true;
        }
      }
    },
    validateJob() {
      if (!this.vCustomerId) {
        ErrorEventBus.$emit("update:error", "Select Customer");
        return false;
      }

      if (!this.vPropertyId) {
        ErrorEventBus.$emit("update:error", "Select Service Location");
        return false;
      }

      if (!this.vPropertyContactId) {
        ErrorEventBus.$emit("update:error", "Select Service Contact Person");
        return false;
      }

      if (!this.vBillingId) {
        ErrorEventBus.$emit("update:error", "Select Billing Location");
        return false;
      }

      if (!this.vBillingContactId) {
        ErrorEventBus.$emit("update:error", "Select Billing Contact Person");
        return false;
      }
      const allHeaders = this.vSelectedLineItem.every(
        (obj) => obj.type === "header"
      );
      if (
        !this.vSelectedLineItem ||
        !this.vSelectedLineItem.length ||
        allHeaders
      ) {
        ErrorEventBus.$emit("update:error", "Select Line Item");
        return false;
      }

      const validateStatus = this.$refs.createQuotationForm.validate();

      const formErrors = this.validateForm(this.$refs.createQuotationForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (validateStatus) {
        this.onSubmit();
      }
    },
    async onSubmit() {
      const new_attachments = await this.uploadFiles();

      const request = {
        ...this.dbQuotation,
        /*  contract: {
           dates: this.contractScheduleOutput,
           schedule: this.contractRecurringSchedule
         }, */
        attachments: [...this.dbQuotation.attachments, ...new_attachments],
        /*  is_contract: + this.contract_status, */
        customer: this.vCustomerId,
        property: this.vPropertyId,
        billing: this.vBillingId,
        property_contact_person: this.vPropertyContactId,
        billing_contact_person: this.vBillingContactId,
        calculation: this.vCalculations,
        "line-item": this.vSelectedLineItem,
        invoice_parameter: this.quoteInvoiceParameters,
        recurring:
          this.quoteInvoiceParameters?.invoice_type == "recurring"
            ? this.invoiceRecurringSchedule
            : {},
        schedule:
          this.quoteInvoiceParameters?.invoice_type == "recurring"
            ? this.invoiceScheduleOutput
            : [],
      };

      let requestType = POST;
      let requestURL = "quotation-v1";

      let quotationId = this.$route?.query?.edit ?? 0;

      if (quotationId) {
        requestType = PATCH;
        requestURL = `quotation-v1/${quotationId}`;
      }

      this.formLoading = true;

      this.$store
        .dispatch(requestType, { url: requestURL, data: request })
        .then(({ data }) => {
          this.quotationCreated = true;
          this.$store.dispatch(CLEAR_CUSTOMER);
          /*  this.$store.dispatch(CLEAR_LINE_ITEM); */
          this.$nextTick(() => {
            this.$router.push(
              this.getDefaultRoute("quotation.detail", {
                params: { id: data.id },
              })
            );
          });
        })
        .catch(() => {
          this.formLoading = false;
        });
    },
    get_attributes() {
      this.$store
        .dispatch(QUERY, {
          url: "quotation/options",
          data: {
            quotation: ObjectPath.get(this.$route, "query.edit"),
            duplicate: ObjectPath.get(this.$route, "query.duplicate"),
            revise: ObjectPath.get(this.$route, "query.revise"),
            requote: ObjectPath.get(this.$route, "query.requote"),
            enquiry: ObjectPath.get(this.$route, "query.enquiry"),
            visit: ObjectPath.get(this.$route, "query.visit"),
          },
        })
        .then((response) => {
          this.dbQuotation.barcode = ObjectPath.get(response, "data.barcode");
          this.quotation_recurring_invoice = ObjectPath.get(
            response,
            "data.quotation_recurring_invoice"
          );
          this.is_quotation_invoice_allow = ObjectPath.get(
            response,
            "data.is_quotation_invoice_allow"
          );
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quotation", route: "quotation" },
            { title: "Create" },
            { title: this.dbQuotation.barcode },
          ]);

          this.adminNoteList = ObjectPath.get(
            response,
            "data.admin_notes_list",
            []
          );
          this.clientNoteList = ObjectPath.get(
            response,
            "data.client_notes_list",
            []
          );
          this.termsConditionList = ObjectPath.get(
            response,
            "data.term_condition_list",
            []
          );

          let defaultTermConditionObject = this.lodash.find(
            this.termsConditionList,
            {
              primary: 1,
            }
          );
          if (defaultTermConditionObject) {
            this.dbQuotation.term_condition_id = defaultTermConditionObject.id;
            this.dbQuotation.term_conditions =
              defaultTermConditionObject.description;
          }

          let defaultClientObject = this.lodash.find(this.clientNoteList, {
            primary: 1,
          });
          if (defaultClientObject) {
            this.dbQuotation.client_note_id = defaultClientObject.id;
            this.dbQuotation.client_note = defaultClientObject.description;
          }

          let defaultAdminObject = this.lodash.find(this.adminNoteList, {
            primary: 1,
          });
          if (defaultAdminObject) {
            this.dbQuotation.admin_note_id = defaultAdminObject.id;
            this.dbQuotation.admin_note = defaultAdminObject.description;
          }

          this.userList = ObjectPath.get(response, "data.sales_users", []);
          /*  this.validPeriod = ObjectPath.get(
            response,
            "data.validity_types",
            []
          ); */

          this.dbQuotation.sale_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );
          this.dbQuotation.quote_by = ObjectPath.get(
            response,
            "data.user_id",
            0
          );

          let dbQuotation = ObjectPath.get(response, "data.quotation");
          let duplicateQuotation = ObjectPath.get(response, "data.duplicate");
          let requoteQuotation = ObjectPath.get(response, "data.requote");
          let reviseQuotation = ObjectPath.get(response, "data.revise");
          let enquiryQuotation = ObjectPath.get(response, "data.enquiry");
          let visitQuotation = ObjectPath.get(response, "data.visit");
          if (dbQuotation?.id) {
            this.dbQuotation.barcode = dbQuotation?.barcode;
            this.updatedQuotationData = ObjectPath.get(
              response,
              "data.quotation"
            );
            this.dbQuotation.title = dbQuotation?.title;
            this.dbQuotation.description = dbQuotation?.description;
            this.dbQuotation.your_reference = dbQuotation?.your_reference;
            this.dbQuotation.reference = dbQuotation?.reference;
            this.dbQuotation.prepared_by_id = dbQuotation?.prepared_by_id;
            this.dbQuotation.sales_executive = dbQuotation?.sales_executive;
            this.dbQuotation.open_till = dbQuotation?.open_till;
            this.dbQuotation.validity_till = dbQuotation?.validity_till;
            this.dbQuotation.type = dbQuotation?.type;
            this.dbQuotation.tags = dbQuotation?.tags;
            this.dbQuotation.is_contract = this.lodash.toSafeInteger(
              dbQuotation?.is_contract
            );

            this.dbQuotation.total_service = this.lodash.toSafeInteger(
              dbQuotation?.total_service
            );
            this.dbQuotation.duration_value = this.lodash.toSafeInteger(
              dbQuotation?.duration_value
            );
            this.dbQuotation.duration_type = dbQuotation?.duration_type;
            this.dbQuotation.contract_start = dbQuotation?.contract_start;
            this.dbQuotation.contract_end = dbQuotation?.contract_end;
            this.dbQuotation.base_type = dbQuotation?.base_type;

            this.dbQuotation.date = moment(dbQuotation?.date).format(
              "YYYY-MM-DD"
            );
            this.dbQuotation.rating = dbQuotation?.rating;
            /* this.dbQuotation.attachments = dbQuotation?.attachments ?? []; */

            this.dbQuotation.notify_billing_contact_person =
              dbQuotation?.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              dbQuotation?.notify_property_contact_person;

            this.dbQuotation.client_note_id = dbQuotation?.client_note_id;
            this.dbQuotation.client_note = dbQuotation?.client_note;

            this.dbQuotation.term_condition_id = dbQuotation?.term_condition_id;
            this.dbQuotation.term_conditions = dbQuotation?.term_conditions;

            this.dbQuotation.admin_note_id = dbQuotation?.admin_note_id;
            this.dbQuotation.admin_note = dbQuotation?.admin_note;

            this.$store.commit(SET_CUSTOMER, dbQuotation?.customer_relation);
            this.$store.commit(SET_PROPERTY, dbQuotation?.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              dbQuotation?.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, dbQuotation?.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              dbQuotation?.billing_contact_person_relation
            );

            /*   this.$store.dispatch(SET_EDIT_CALCULATION, {
                ctx_discount_label: dbQuotation?.ctx_discount_label ?? null,
                ctx_discount_value: dbQuotation?.ctx_discount_value ?? 0,
                ctx_discount_type: dbQuotation?.ctx_discount_type ?? 1,
                discount_type: dbQuotation?.discount_type ?? 1,
                discount_value: dbQuotation?.discount_value ?? 0,
                tax_active: dbQuotation?.tax_active ?? false,
                adjustment: dbQuotation?.adjustment ?? 0,
                show_price: true,
                discount_level: dbQuotation?.discount_level ?? "transaction",
              }); */

            /*  this.$store.dispatch(SET_EDIT_LINE_ITEM, dbQuotation?.line_item ?? []); */

            this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING_QUOTE, {
              key: "quoteInvoiceParameters.invoice_needed",
              value: dbQuotation?.invoice_needed,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING_QUOTE, {
              key: "quoteInvoiceParameters.invoice_type",
              value: dbQuotation?.invoice_type,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING_QUOTE, {
              key: "quoteInvoiceParameters.invoice_date",
              value: dbQuotation?.invoice_date
                ? dbQuotation?.invoice_date
                : this.todayDate,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING_QUOTE, {
              key: "quoteInvoiceParameters.payment_due",
              value: dbQuotation?.payment_due,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING_QUOTE, {
              key: "quoteInvoiceParameters.invoice_generate_type",
              value: dbQuotation?.invoice_generate_type,
            });
            this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING_QUOTE, {
              key: "quoteInvoiceParameters.invoice_amount_type",
              value: dbQuotation?.invoice_amount_type,
            });

            if (dbQuotation?.invoice_type == "recurring") {
              const schedule =
                response?.data?.daily ??
                response?.data?.weekly ??
                response?.data?.monthly;

              const startedAt = new Date(
                `${schedule?.start_date} ${schedule?.start_time}`
              );
              const finishedAt = new Date(
                `${schedule?.end_date} ${schedule?.end_time}`
              );

              /*    this.$store.commit(UPDATE_DEFAULT_DATE, { startedAt, finishedAt }); */

              const type = this.recurringInvoiceScheduleTypeList.find(
                (row) => row.value == "daily"
              );
              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.type",
                value: type,
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.weeks",
                value: schedule?.weeks ?? [],
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.occurrence",
                value: this.lodash.toSafeInteger(schedule.occurrence, 1),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.end_mode",
                value: this.lodash.toSafeInteger(schedule.end_mode, 1),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.start_date",
                value: moment(startedAt).format("YYYY-MM-DD"),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.end_date",
                value: moment(finishedAt).format("YYYY-MM-DD"),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.start_time",
                value: moment(startedAt).format("hh:mm A"),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.end_time",
                value: moment(finishedAt).format("hh:mm A"),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.week_day",
                value: this.lodash.toSafeInteger(schedule.week_day, 1),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.week_count",
                value: this.lodash.toSafeInteger(schedule.week_count, 1),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.second_week_day",
                value: this.lodash.toSafeInteger(schedule.second_week_day, 1),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.second_week_count",
                value: this.lodash.toSafeInteger(schedule.second_week_count, 1),
              });

              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.recurring_pattern",
                value: this.lodash.toSafeInteger(schedule.recurring_pattern, 1),
              });
              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbRecurringSchedule.recurring_duration_new",
                value: this.lodash.toSafeInteger(schedule.repeatation, 1),
              });
              this.$store.commit(UPDATE_SCHEDULE_STATE, {
                key: "dbInvoiceQuoteUpdateSchedule",
                value: response?.data?.invoice_schedule,
              });

              this.$store.commit(UPDATE_SCHEDULE_LIST_QUOTE, {
                key: "dbScheduleOutput",
                value: this.dbInvoiceUpdateSchedule,
              });
            }

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Quotation", route: "quotation" },
              { title: "Update" },
              { title: this.dbQuotation.barcode },
            ]);
          } else if (duplicateQuotation?.id) {
            this.dbQuotation.q_duplicate_barcode = duplicateQuotation.barcode;
            this.dbQuotation.quotation_duplicate = duplicateQuotation.id;
            this.updatedQuotationData = ObjectPath.get(
              response,
              "data.duplicate"
            );
            this.dbQuotation.title = duplicateQuotation.title;
            this.dbQuotation.description = duplicateQuotation.description;
            this.dbQuotation.your_reference = duplicateQuotation.your_reference;
            this.dbQuotation.reference = duplicateQuotation.reference;
            this.dbQuotation.prepared_by_id = duplicateQuotation.prepared_by_id;
            this.dbQuotation.sales_executive =
              duplicateQuotation.sales_executive;
            this.dbQuotation.open_till = duplicateQuotation.open_till;
            this.dbQuotation.validity_till = duplicateQuotation.validity_till;
            this.dbQuotation.type = duplicateQuotation.type;
            this.dbQuotation.tags = duplicateQuotation.tags;
            this.dbQuotation.date = moment(duplicateQuotation.date).format(
              "YYYY-MM-DD"
            );
            this.dbQuotation.rating = duplicateQuotation.rating;
            this.dbQuotation.attachments = [];

            this.dbQuotation.notify_billing_contact_person =
              duplicateQuotation.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              duplicateQuotation.notify_property_contact_person;

            this.dbQuotation.client_note_id = duplicateQuotation.client_note_id;
            this.dbQuotation.client_note = duplicateQuotation.client_note;

            this.dbQuotation.term_condition_id =
              duplicateQuotation.term_condition_id;
            this.dbQuotation.term_conditions =
              duplicateQuotation.term_conditions;

            this.dbQuotation.admin_note_id = duplicateQuotation.adminte_id;
            this.dbQuotation.admin_note = duplicateQuotation.admin_note;
            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Duplicate", route: "quotation" },
              { title: "Duplicate" },
              { title: this.dbQuotation.barcode },
            ]);
          } else if (requoteQuotation?.id) {
            this.dbQuotation.q_requote_barcode = requoteQuotation.barcode;
            this.dbQuotation.quotation_requote = requoteQuotation.id;
            this.updatedQuotationData = ObjectPath.get(
              response,
              "data.requote"
            );
            this.dbQuotation.title = requoteQuotation.title;
            this.dbQuotation.description = requoteQuotation.description;
            this.dbQuotation.your_reference = requoteQuotation.your_reference;
            this.dbQuotation.reference = requoteQuotation.reference;
            this.dbQuotation.prepared_by_id = requoteQuotation.prepared_by_id;
            this.dbQuotation.sales_executive = requoteQuotation.sales_executive;
            this.dbQuotation.open_till = requoteQuotation.open_till;
            this.dbQuotation.validity_till = requoteQuotation.validity_till;
            this.dbQuotation.type = requoteQuotation.type;
            this.dbQuotation.tags = requoteQuotation.tags;
            this.dbQuotation.date = moment(requoteQuotation.date).format(
              "YYYY-MM-DD"
            );
            this.dbQuotation.rating = requoteQuotation.rating;
            this.dbQuotation.attachments = requoteQuotation.attachments ?? [];

            this.dbQuotation.notify_billing_contact_person =
              requoteQuotation.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              requoteQuotation.notify_property_contact_person;

            this.dbQuotation.client_note_id = requoteQuotation.client_note_id;
            this.dbQuotation.client_note = requoteQuotation.client_note;

            this.dbQuotation.term_condition_id =
              requoteQuotation.term_condition_id;
            this.dbQuotation.term_conditions = requoteQuotation.term_conditions;

            this.dbQuotation.admin_note_id = requoteQuotation.admin_note_id;
            this.dbQuotation.admin_note = requoteQuotation.admin_note;

            this.$store.commit(
              SET_CUSTOMER,
              requoteQuotation.customer_relation
            );
            this.$store.commit(
              SET_PROPERTY,
              requoteQuotation.property_relation
            );
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              requoteQuotation.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, requoteQuotation.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              requoteQuotation.billing_contact_person_relation
            );

            /*   this.$store.dispatch(SET_EDIT_CALCULATION, {
                ctx_discount_label: duplicateQuotation.ctx_discount_label ?? null,
                ctx_discount_value: duplicateQuotation.ctx_discount_value ?? 0,
                ctx_discount_type: duplicateQuotation.ctx_discount_type ?? 1,
                discount_type: duplicateQuotation.discount_type ?? 1,
                discount_value: duplicateQuotation.discount_value ?? 0,
                tax_active: duplicateQuotation.tax_active ?? false,
                adjustment: duplicateQuotation.adjustment ?? 0,
                show_price: true,
                discount_level: duplicateQuotation.discount_level ?? "transaction",
              }); */

            /*  this.$store.dispatch(SET_EDIT_LINE_ITEM, duplicateQuotation.line_item ?? []); */

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Duplicate", route: "quotation" },
              { title: "Duplicate" },
              { title: this.dbQuotation.barcode },
            ]);
          } else if (reviseQuotation?.id) {
            this.dbQuotation.q_revise_barcode = reviseQuotation.barcode;
            this.dbQuotation.quotation_revise = reviseQuotation.id;
            this.updatedQuotationData = ObjectPath.get(response, "data.revise");
            this.dbQuotation.title = reviseQuotation.title;
            this.dbQuotation.description = reviseQuotation.description;
            this.dbQuotation.your_reference = reviseQuotation.your_reference;
            this.dbQuotation.reference = reviseQuotation.reference;
            this.dbQuotation.prepared_by_id = reviseQuotation.prepared_by_id;
            this.dbQuotation.sales_executive = reviseQuotation.sales_executive;
            this.dbQuotation.open_till = reviseQuotation.open_till;
            this.dbQuotation.validity_till = reviseQuotation.validity_till;
            this.dbQuotation.type = reviseQuotation.type;
            this.dbQuotation.tags = reviseQuotation.tags;
            this.dbQuotation.date = moment(reviseQuotation.date).format(
              "YYYY-MM-DD"
            );
            this.dbQuotation.rating = reviseQuotation.rating;
            this.dbQuotation.attachments = reviseQuotation.attachments ?? [];

            this.dbQuotation.notify_billing_contact_person =
              reviseQuotation.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              reviseQuotation.notify_property_contact_person;

            this.dbQuotation.client_note_id = reviseQuotation.client_note_id;
            this.dbQuotation.client_note = reviseQuotation.client_note;

            this.dbQuotation.term_condition_id =
              reviseQuotation.term_condition_id;
            this.dbQuotation.term_conditions = reviseQuotation.term_conditions;

            this.dbQuotation.admin_note_id = reviseQuotation.admin_note_id;
            this.dbQuotation.admin_note = reviseQuotation.admin_note;

            this.$store.commit(SET_CUSTOMER, reviseQuotation.customer_relation);
            this.$store.commit(SET_PROPERTY, reviseQuotation.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              reviseQuotation.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, reviseQuotation.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              reviseQuotation.billing_contact_person_relation
            );

            /*   this.$store.dispatch(SET_EDIT_CALCULATION, {
                ctx_discount_label: duplicateQuotation.ctx_discount_label ?? null,
                ctx_discount_value: duplicateQuotation.ctx_discount_value ?? 0,
                ctx_discount_type: duplicateQuotation.ctx_discount_type ?? 1,
                discount_type: duplicateQuotation.discount_type ?? 1,
                discount_value: duplicateQuotation.discount_value ?? 0,
                tax_active: duplicateQuotation.tax_active ?? false,
                adjustment: duplicateQuotation.adjustment ?? 0,
                show_price: true,
                discount_level: duplicateQuotation.discount_level ?? "transaction",
              }); */

            /*  this.$store.dispatch(SET_EDIT_LINE_ITEM, duplicateQuotation.line_item ?? []); */

            this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Duplicate", route: "quotation" },
              { title: "Duplicate" },
              { title: this.dbQuotation.barcode },
            ]);
          } else if (enquiryQuotation?.id) {
            this.dbQuotation.enquiry_barcode = enquiryQuotation.barcode;
            this.dbQuotation.enquiry_id = enquiryQuotation.id;
            this.updatedQuotationData = ObjectPath.get(
              response,
              "data.enquiry"
            );
            this.dbQuotation.title = enquiryQuotation.title;
            this.dbQuotation.your_reference = enquiryQuotation.reference;
            this.dbQuotation.prepared_by_id = enquiryQuotation.assign;

            this.dbQuotation.tags = enquiryQuotation.tags;

            this.dbQuotation.notify_billing_contact_person =
              enquiryQuotation.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              enquiryQuotation.notify_property_contact_person;
            this.dbQuotation.admin_note = enquiryQuotation.technician_sketch;

            this.$store.commit(
              SET_CUSTOMER,
              enquiryQuotation.customer_relation
            );
            this.$store.commit(
              SET_PROPERTY,
              enquiryQuotation.property_relation
            );
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              enquiryQuotation.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, enquiryQuotation.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              enquiryQuotation.billing_contact_person_relation
            );
          } else if (visitQuotation?.id) {
            this.dbQuotation.visit_barcode = visitQuotation.barcode;
            this.dbQuotation.visit = visitQuotation.id;
            this.updatedQuotationData = ObjectPath.get(response, "data.visit");
            this.dbQuotation.title = visitQuotation.title;
            this.dbQuotation.your_reference = visitQuotation.reference;
            this.dbQuotation.prepared_by_id = visitQuotation.assign;

            this.dbQuotation.tags = visitQuotation.tags;

            this.dbQuotation.notify_billing_contact_person =
              visitQuotation.notify_billing_contact_person;
            this.dbQuotation.notify_property_contact_person =
              visitQuotation.notify_property_contact_person;
            this.dbQuotation.admin_note = visitQuotation.technician_sketch;

            this.$store.commit(SET_CUSTOMER, visitQuotation.customer_relation);
            this.$store.commit(SET_PROPERTY, visitQuotation.property_relation);
            this.$store.commit(
              SET_PROPERTY_CONTACT,
              visitQuotation.property_contact_person_relation
            );
            this.$store.commit(SET_BILLING, visitQuotation.billing_relation);
            this.$store.commit(
              SET_BILLING_CONTACT,
              visitQuotation.billing_contact_person_relation
            );
          }
        })
        .catch((error) => {
          this.logError(error);
          this.goBack();
        })
        .finally(() => {
          // this.formLoading = false;
        });
    },
    getCustomer(customerId, contactPersonId, propertyId) {
      this.$store
        .dispatch(QUERY, {
          url: `customer-with-relation/${customerId}`,
          data: {
            contact_person: contactPersonId,
            property: propertyId,
          },
        })
        .then((response) => {
          this.$store.commit(
            SET_CUSTOMER,
            ObjectPath.get(response, "data.customer", {})
          );
          this.$store.commit(
            SET_PROPERTY,
            ObjectPath.get(response, "data.property", {})
          );
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            ObjectPath.get(response, "data.property_contact_person", {})
          );
          this.$store.commit(
            SET_BILLING,
            ObjectPath.get(response, "data.billing", {})
          );
          this.$store.commit(
            SET_BILLING_CONTACT,
            ObjectPath.get(response, "data.billing_contact_person", {})
          );
        })
        .catch(() => {
          this.goBack();
        })
        .finally(() => {
          //
        });
    },
  },
  mounted() {
    this.$refs.createQuotationForm.validate();
    this.get_attributes();
    const customerId = Number(this.$route?.query?.customer ?? 0);
    const contactPersonId = Number(this.$route?.query?.contact_person ?? 0);
    const propertyId = Number(this.$route?.query?.property ?? 0);
    this.quotation_Id = Number(this.$route?.query?.edit ?? 0);

    if (customerId) {
      this.getCustomer(customerId, contactPersonId, propertyId);
    }
    this.$store.dispatch(SET_BREADCRUMB, []);
    if (ObjectPath.get(this.$route, "query.enquiry") > 0) {
      this.related_type = 6;
      this.tag_type = "enquiry";
    } else if (ObjectPath.get(this.$route, "query.visit") > 0) {
      this.related_type = 3;
      this.tag_type = "visit";
    } else {
      this.related_type = 1;
      this.tag_type = "quotation";
    }
  },
  destroyed() {
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    this.$store.dispatch(CLEAR_INVOICE);
    /*  this.$store.dispatch(CLEAR_LINE_ITEM); */
  },
  beforeDestroy() {
    KTCookie.setCookie("kt_aside_toggle_state", "off");
    localStorage.setItem("kt_aside_toggle_state", "off");
  },
  created() {
    this.todayDate = moment().format("YYYY-MM-DD");
    this.dbQuotation.date = this.todayDate;
    this.dbQuotation.invoice_date = this.todayDate;
    this.dbQuotation.enquiry = this.lodash.toSafeInteger(
      ObjectPath.get(this.$route, "query.enquiry")
    );
  },
  beforeCreate() {
    KTCookie.setCookie("kt_aside_toggle_state", "on");
    localStorage.setItem("kt_aside_toggle_state", "on");
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(RESET_CREATE_STATE);
    /*  this.$store.dispatch(CLEAR_LINE_ITEM); */
  },
  computed: {
    ...mapGetters([
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "reCustomerDialog",
      "vSelectedLineItem",
      "vCalculations",
      "recurringInvoiceScheduleTypeList",
      "invoiceRecurringSchedule",
      "invoiceScheduleOutput",
      "customError",
      "quoteInvoiceParameters",
      "invoiceRawScheduleOutput",
      "dbInvoiceUpdateSchedule",
      "customErrorOcc",
    ]),
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.quotationCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.quotation-create-page {
  border-top: solid 8px #f7941e;

  label {
    padding-left: 5px;
    font-weight: 600;
  }

  p {
    padding-left: 5px;
  }
}

.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;

    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
